<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="13"></user-left>
        </div>
        <div class="col-10 f-14">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12 d-flex justify-content-between">
              <h6>农机具管理</h6>
              <router-link class="text-primary" to="/user/land/add" target="_blank">
                <img src="@/assets/icon/fbtd.png" style="width: 24px;"/>
                <span class="ml-1">发布农机具信息</span>
              </router-link>
            </div>
            <div class="col-12">
              <div class="clearfix mt-4 d-flex align-items-center">
                <div class="float-left">
                  更新时间：
                </div>
                <div class="float-left ml-2">
                  <b-form-input type="date" v-model="para.param.startTime"></b-form-input>
                </div>
                <div class="float-left ml-2 mr-2">
                  至
                </div>
                <div class="float-left">
                  <b-form-input type="date" v-model="para.param.endTime"></b-form-input>
                </div>
                <div class="float-left ml-3">
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(1)" :class="{'active':yues==1}" >近一月</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(3)" :class="{'active':yues==3}">近三月</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(6)" :class="{'active':yues==6}">近半年</a>
                  <a class="ml-3" href="javascript:;" @click="createBeginDate(12)" :class="{'active':yues==12}">近一年</a>
                </div>
              </div>
              <div class="clearfix mt-2 d-flex align-items-center">
                <div class="float-left">
                  农机状态：
                </div>
                <div class="float-left ml-4">
                  <div class="row">
                    <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(1)" :class="{'active':type==1}">全部</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1"  @click="clicktype(2)":class="{'active':type==2}">待交易</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1"  @click="clicktype(3)" :class="{'active':type==3}">等待审核</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1"  @click="clicktype(4)" :class="{'active':type==4}">审核未通过</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(5)" :class="{'active':type==5}">暂停</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1" @click="clicktype(6)" :class="{'active':type==6}">已过期</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1"  @click="clicktype(7)" :class="{'active':type==7}">已交易</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1"  @click="clicktype(8)" :class="{'active':type==8}">信息被代理</a>
                  </div>
                </div>
              </div>
              <div class="clearfix mt-2 d-flex align-items-center">
                <div class="float-left">
                  服务类型：
                </div>
                <div class="float-left ml-4">
                  <div class="row">
                    <a href="javascript:;" class="mr-3 px-2 py-1" @click="clickfwtype(1)" :class="{'active':fwtype==1}">全部</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1" @click="clickfwtype(2)" :class="{'active':fwtype==2}">出售</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1" @click="clickfwtype(3)" :class="{'active':fwtype==3}">出租</a>
                  </div>
                </div>
              </div>

              <div class="clearfix mt-2 d-flex align-items-center">
                <div class="float-left">
                  标题检索：
                </div>
                <div class="float-left ml-4">
                  <div class="row">
                    <input class="form-control mr-sm-2" type="search" placeholder="输入农机具关键词">
                  </div>
                </div>
                <div class="float-left ml-3">
                  <b-button size="sm" variant="success" type="submit" style="width:100px">搜索</b-button>
                </div>

              </div>

            </div>
            <div class="col-12 mt-4 mb-4">
              <div class="card border-0">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-centered border mb-0">
                      <thead class="table-light">
                      <tr>
                        <th class="w-65">农机具信息</th>
                        <th>更新时间</th>
                        <th>状态</th>
                        <th style="width: 80px">操作</th>
                      </tr>
                      </thead>
                      <tbody>
                      <!--                        <tr v-for="i in 10" :key="i">
                                                <td>
                                                  <div class="clearfix">
                                                    <div class="float-left">
                                                      <img src="https://fc6tn.baidu.com/it/u=3030194471,2046924699&fm=202" class="width-80 rounded"/>
                                                    </div>
                                                    <div class="float-left ml-3">
                                                        <h6>
                                                            <b-badge v-if="i == 1" class="float-left mr-2" variant="success">出租</b-badge>
                                                            <b-badge v-else class="float-left mr-2" variant="warning">出售</b-badge>
                                                            {{ i== 1 ? '呼伦贝尔额尔古纳市 收割机 0.7元/平方米 出租' : '呼伦贝尔额尔古纳市 二手 15年 保田收割机'}}
                                                        </h6>
                                                        <div class="mt-2 text-gray-777">
                                                          <span v-if="i == 1">价格：0.7元/平方米</span>
                                                          <span v-else>价格：17万元</span>
                                                          <span class="ml-2">出产年份：2015年6月</span>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div class="mt-1">2021-07-31 10:29</div>
                                                </td>

                                                <td>
                                                  <h5>
                                                    <span class="badge badge-danger f-12"
                                                      >待交易</span
                                                    >
                                                  </h5>
                                                </td>
                                                <td>
                                                  <div class="mt-1 mb-1 f-16">
                                                    <router-link to="/user/land/add" target="_blank">
                                                      <b-icon icon="pencil"></b-icon>
                                                    </router-link>
                                                    <a href="javascript:void(0);" class="ml-2">
                                                      <b-icon icon="trash"></b-icon>
                                                    </a>
                                                  </div>
                                                </td>
                                              </tr>-->
                      <tr>
                        <td colspan="4">
                          <div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                            <div class="text-gray-777">没有找到农机具，您可以马上</div>
                            <div class="ml-3">
                              <b-button variant="success" @click="addMachinery">发布农机具</b-button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div></div>
                    <div>
                      <b-pagination class="mt-1 mb-1" :total-rows="10" :per-page="1"
                                    aria-controls="my-table"></b-pagination>
                    </div>
                  </div>
                </div>
                <!-- end card-body-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
export default {
  name: "machinery",
  data() {
    return {
      list: [],
      para: {
        param: {
          startTime: "",
          endTime: "",
        },
      },
      yues:'',
      type:1,
      fwtype:1
    };
  },
  methods: {
    clicktype(type){
      this.type=type;
    },
    clickfwtype(fwtype){
      this.fwtype=fwtype;
    },
    //获取当前日期几个月内的数据
    createBeginDate(yues) {
      this.yues = yues;
      var date = new Date();
      date.setMonth(date.getMonth() - yues);
      date.toLocaleDateString()
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d

      var dqdate = new Date();
      dqdate.toLocaleDateString()
      var b = dqdate.getFullYear()
      var e = dqdate.getMonth() + 1
      e = e < 10 ? ('0' + e) : e
      var q = dqdate.getDate()
      q = q < 10 ? ('0' + q) : q

      const dqtime = b + '-' + e + '-' + q;
      this.para.param.startTime = time;
      this.para.param.endTime = dqtime;
      this.getList();
    },
    getList() {
      this.$http
          .post(this.$api.user.getBankCard)
          .then((res) => {
            this.list = res.result;
          });
    },

    addMachinery(){
      let u = this.$router.resolve({path: '/user/land/add'});
      window.open(u.href, '_blank');
    }
  },
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");

.form-control {
  height: 31px;
}

.active {
  background: #00cc00;
  padding: 5px;
  color: #FFFFFF;
  border-radius: 4px;
}
</style>
